<template>
  <div class="wrap">
    <ul class="dotlist lh18">
      <li class="ellipsis cus_link" v-for="(item, i) in list" :key="'link' + i">
        <el-link :underline="false" @click="onClick(item.url)">
          {{ item.label }}
        </el-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "JGGK",
  data() {
    return {
      list: [
        {
          label: "国家放射影像专业医疗质量控制中心专家委员会委员名单",
          url: "/WYMD",
        },
        {
          label: "国家级放射影像专业质控中心哨点医院工作制度",
          url: "/YYGZZD",
        },
        {
          label: "国家级放射影像专业质控中心专家委员会工作章程",
          url: "/WYYGZZC",
        },
        {
          label: "国家级放射影像专业质控中心亚专业指标专家工作组工作章程",
          url: "/ZJZGZZC",
        },
      ]
    }
  },
  methods: {
    onClick(url) {
      this.$router.push(url)
    }
  }
}
</script>

<style scoped>
.wrap {
  width: 70%;
  margin: 0 auto;
  background: #fff;
  min-height: 80vh;
  padding: 10px;
  box-sizing: border-box;
}

.title {
  text-align: center;
  font-size: 20px;
  margin: 20px;
  font-weight: 500;
}

.lh18 {
  line-height: 1.8;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

ul,
li {
  list-style-type: none;
}

.dotlist li {
  /* background: url(../images/dot.png) left center no-repeat; */
  padding: 5px 0px;
  padding-left: 10px;
}

.ellipsis {}

.el-link::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #777;
  margin-right: 8px;
  vertical-align: middle;
}


a,
a:link {
  color: #777;
  transition: all 0.3s;
}

a {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer;
}

.cus_link {
  margin-top: 20px;
}

.cus_link>>>.el-link {
  font-size: 16px;
}

.cus_link>>>.el-link {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
</style>